import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";
import { createId, Nullable } from "@tager/admin-services";

import { DealerFullInterface, DealerType } from "@/modules/dealers/typings";
import { DealerUpdatePayload } from "@/modules/dealers/requests";

export type FormValues = {
  type: DealerType | null;
  serviceAvailable: boolean;
  name: string;
  shortDescription: string;
  urlSlug: string;
  apiDealer: Nullable<OptionType<number>>;
  brands: Array<OptionType<number>>;
  hideVin: boolean;
  hidePrices: boolean;

  legalName: string | null;
  image: Nullable<SingleFileInputValueType>;
  imageCover: Nullable<SingleFileInputValueType>;
  pageTitle: string | null;
  pageDescription: string | null;
  seoText: string | null;
  disablePartsOrder: boolean;

  isHeroBannerBlockActive: boolean;
  heroBannerTitle: Nullable<string>;
  heroBannerDescription: Nullable<string>;

  isAboutBlockActive: boolean;
  aboutBlockImage: Nullable<SingleFileInputValueType>;
  aboutBlockDescription: Nullable<string>;

  isRequestsBannersActive: boolean;

  isStockActive: boolean;
  stockBrands: Array<OptionType<number>>;

  isSmallBannerActive: boolean;
  smallBannerImage: Nullable<SingleFileInputValueType>;
  smallBannerTitle: Nullable<string>;
  smallBannerDescription: Nullable<string>;

  isTestDriveActive: boolean;
  testDriveImage: Nullable<SingleFileInputValueType>;
  testDriveDescription: Nullable<string>;

  isFinanceServiceActive: boolean;
  isServiceServiceActive: boolean;
  isJournalActive: boolean;
  isCommonBannersActive: boolean;
  isReviewsActive: boolean;
  isVideosActive: boolean;
  isMapActive: boolean;
};

export function getDealerFormValue(
  dealer: DealerFullInterface,
  apiDealersOptions: Array<OptionType<number>>,
  brandsOptions: Array<OptionType<number>>
): FormValues {
  return {
    type: dealer.type ?? null,
    serviceAvailable: dealer.serviceAvailable,
    name: dealer.name || "",
    shortDescription: dealer.shortDescription || "",
    urlSlug: dealer.urlSlug || "",
    apiDealer:
      apiDealersOptions.find((item) => item.value === dealer.apiDealer) || null,
    brands:
      brandsOptions?.filter((item) => dealer.brands?.includes(item.value)) ||
      [],
    hideVin: dealer.hideVin,
    hidePrices: dealer.hidePrices,

    legalName: dealer.legalName,
    image: dealer.image
      ? {
          id: createId(),
          file: dealer.image,
          caption: "",
        }
      : null,
    imageCover: dealer.imageCover
      ? {
          id: createId(),
          file: dealer.imageCover,
          caption: "",
        }
      : null,

    pageTitle: dealer.pageTitle,
    pageDescription: dealer.pageDescription,
    seoText: dealer.seoText,
    disablePartsOrder: dealer.disablePartsOrder,

    isHeroBannerBlockActive: dealer.content.isHeroBannerBlockActive,
    heroBannerTitle: dealer.content.heroBannerTitle,
    heroBannerDescription: dealer.content.heroBannerDescription,

    isAboutBlockActive: dealer.content.isAboutBlockActive,
    aboutBlockImage: dealer.content.aboutBlockImage
      ? {
          id: createId(),
          file: dealer.content.aboutBlockImage,
          caption: "",
        }
      : null,
    aboutBlockDescription: dealer.content.aboutBlockDescription,

    isRequestsBannersActive: dealer.content.isRequestsBannersActive,

    isStockActive: dealer.content.isStockActive,
    stockBrands:
      brandsOptions?.filter((item) =>
        dealer.content.stockBrands?.includes(item.value)
      ) || [],

    isSmallBannerActive: dealer.content.isSmallBannerActive,
    smallBannerImage: dealer.content.smallBannerImage
      ? {
          id: createId(),
          file: dealer.content.smallBannerImage,
          caption: "",
        }
      : null,
    smallBannerTitle: dealer.content.smallBannerTitle,
    smallBannerDescription: dealer.content.smallBannerDescription,

    isTestDriveActive: dealer.content.isTestDriveActive,
    testDriveImage: dealer.content.testDriveImage
      ? { id: createId(), file: dealer.content.testDriveImage, caption: "" }
      : null,
    testDriveDescription: dealer.content.testDriveDescription,

    isFinanceServiceActive: dealer.content.isFinanceServiceActive,
    isServiceServiceActive: dealer.content.isServiceServiceActive,
    isJournalActive: dealer.content.isJournalActive,
    isCommonBannersActive: dealer.content.isCommonBannersActive,
    isReviewsActive: dealer.content.isReviewsActive,
    isVideosActive: dealer.content.isVideosActive,
    isMapActive: dealer.content.isMapActive,
  };
}

export function convertPageFormValuesToUpdatePayload(
  values: FormValues
): DealerUpdatePayload {
  return {
    type: values.type,
    serviceAvailable: values.serviceAvailable,
    name: values.name,
    shortDescription: values.shortDescription,
    urlSlug: values.urlSlug,
    apiDealer: values.apiDealer?.value || null,
    brands: values.brands.map((item) => item.value),
    hideVin: values.hideVin,
    hidePrices: values.hidePrices,

    legalName: values.legalName,
    image: values.image?.file.id || null,
    imageCover: values.imageCover?.file.id || null,
    pageTitle: values.pageTitle,
    pageDescription: values.pageDescription,
    seoText: values.seoText,
    disablePartsOrder: values.disablePartsOrder,

    isHeroBannerBlockActive: values.isHeroBannerBlockActive,
    heroBannerTitle: values.heroBannerTitle,
    heroBannerDescription: values.heroBannerDescription,

    isAboutBlockActive: values.isAboutBlockActive,
    aboutBlockImage: values.aboutBlockImage?.file.id || null,
    aboutBlockDescription: values.aboutBlockDescription,

    isRequestsBannersActive: values.isRequestsBannersActive,

    isStockActive: values.isStockActive,
    stockBrands: values.stockBrands.map((item) => item.value),

    isSmallBannerActive: values.isSmallBannerActive,
    smallBannerImage: values.smallBannerImage?.file.id || null,
    smallBannerTitle: values.smallBannerTitle,
    smallBannerDescription: values.smallBannerDescription,

    isTestDriveActive: values.isTestDriveActive,
    testDriveImage: values.testDriveImage?.file.id || null,
    testDriveDescription: values.testDriveDescription,

    isFinanceServiceActive: values.isFinanceServiceActive,
    isServiceServiceActive: values.isServiceServiceActive,
    isJournalActive: values.isJournalActive,
    isCommonBannersActive: values.isCommonBannersActive,
    isReviewsActive: values.isReviewsActive,
    isVideosActive: values.isVideosActive,
    isMapActive: values.isMapActive,
  };
}
