import { FileType, Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

export enum DealerType {
  New = "new",
  Amp = "amp",
}

export const DealerTypeOptions: Array<OptionType<DealerType | null>> = [
  { value: DealerType.New, label: "Новые автомобили" },
  { value: DealerType.Amp, label: "Автомобили с пробегом" },
  { value: null, label: "Нет" },
];

export interface DealerContent {
  readonly isHeroBannerBlockActive: boolean;
  readonly heroBannerTitle: Nullable<string>;
  readonly heroBannerDescription: Nullable<string>;

  readonly isAboutBlockActive: boolean;
  readonly aboutBlockImage: Nullable<FileType>;
  readonly aboutBlockDescription: Nullable<string>;

  readonly isRequestsBannersActive: boolean;

  readonly isStockActive: boolean;
  readonly stockBrands: number[];

  readonly isSmallBannerActive: boolean;
  readonly smallBannerImage: Nullable<FileType>;
  readonly smallBannerTitle: Nullable<string>;
  readonly smallBannerDescription: Nullable<string>;

  readonly isTestDriveActive: boolean;
  readonly testDriveImage: Nullable<FileType>;
  readonly testDriveDescription: Nullable<string>;

  readonly isFinanceServiceActive: boolean;
  readonly isServiceServiceActive: boolean;
  readonly isJournalActive: boolean;
  readonly isCommonBannersActive: boolean;
  readonly isReviewsActive: boolean;
  readonly isVideosActive: boolean;
  readonly isMapActive: boolean;
}

export interface DealerInterface {
  readonly id: number;
  readonly type: DealerType | null;
  readonly serviceAvailable: boolean;
  readonly name: string;
  readonly image: string;
  readonly apiId: number;
  readonly brandIds: number[];
  readonly websiteUrl: string;
  readonly rating: number;
  readonly reviewsCount: number;
  readonly locationsCount: number;
  readonly canDelete: boolean;
}

export interface DealerFullInterface {
  readonly id: number;
  readonly type: DealerType | null;
  readonly serviceAvailable: boolean;
  readonly name: string;
  readonly shortDescription: string;
  readonly urlSlug: string;
  readonly apiDealer: number;
  readonly brands: number[];
  readonly hideVin: boolean;
  readonly hidePrices: boolean;
  readonly legalName: string | null;
  readonly image: Nullable<FileType>;
  readonly imageCover: Nullable<FileType>;
  readonly pageTitle: string | null;
  readonly pageDescription: string | null;
  readonly seoText: string | null;
  readonly disablePartsOrder: boolean;
  readonly content: DealerContent;
}
